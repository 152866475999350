<template>
    <v-main>
        <v-col sm="12" class="mb-6 mt-3 pa-0" align="right">
            <ExportButton
                @onClick="type === 'eligibles' ? onExportEligibles() : onExportIneligibles()"
                :status="exportTitulosFromOutgoFileRequestStatus"
            />
        </v-col>
        <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            :show-select="canSelect"
            class="elevation-1 pb-4"
            item-key="coverageId"
            v-model="selectedRows"
            @item-selected="itemSelected"
        >
            <template slot="no-data">
                <div>Não há dados</div>
            </template>

            <template v-slot:[`item.coverageDueDate`]="{ item }">
                {{ item ? formatDate(item.coverageDueDate) : null }}
            </template>

            <template v-slot:footer>
                <v-pagination
                    color="secondary"
                    v-model="page"
                    class="mt-3"
                    :length="titulosModalListResult.totalPages"
                    :total-visible="7"
                ></v-pagination>
            </template>
        </v-data-table>
        <Loader
            v-if="titulosModalListRequestStatus === RequestStatusEnum.START"
            class="text-center mt-3"
        />
    </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import headers from './_modalTitulosHeaders';
import { formatDate } from '@/helpers/data';
import Loader from '@/components/base/Loader';
import { RequestStatusEnum } from '@/helpers/consts';
import ExportButton from '@/components/base/ExportButton';
export default {
    components: {
        Loader,
        ExportButton,
    },
    props: {
        canSelect: {
            type: Boolean,
            default: false,
        },
        id: {
            type: Number,
        },
        type: {
            type: String,
        },
    },
    data() {
        return {
            headers,
            selectedRows: [],
            totalPages: null,
            page: 1,
            removedItems: [],
            items: [],
            RequestStatusEnum,
        };
    },

    methods: {
        ...mapActions('gestao', {
            fetchTitulosModal: 'fetchTitulosModal',
            exportTitulosFromOutgo: 'exportTitulosFromOutgo',
        }),
        load() {
            this.removedItems = [];
            this.fetchTitulosModal({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                type: this.type,
                page: this.page - 1,
            });
        },
        checkAll(status) {
            if (status) {
                this.selectedRows = this.items.filter(item => {
                    return this.removedItems.indexOf(item.id) < 0;
                });
            }
        },
        itemSelected(newValue) {
            if (newValue.value === false) {
                this.removedItems.push(newValue.item.coverageId);
            } else {
                this.removedItems = this.removedItems.filter(
                    value => value !== newValue.item.coverageId
                );
            }
            this.$emit('onItemRemove', this.removedItems);
        },
        formatDate,
        onExportEligibles() {
            this.exportTitulosFromOutgo({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                type: 'eligibles',
            });
        },
        onExportIneligibles() {
            this.exportTitulosFromOutgo({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                type: 'ineligibles',
            });
        },
    },
    watch: {
        page() {
            this.fetchTitulosModal({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                type: this.type,
                page: this.page - 1,
            });
        },
        titulosModalListResult(newValue) {
            this.items = newValue.items;
            this.checkAll(this.canSelect);
        },
    },
    computed: {
        ...mapGetters('gestao', {
            selectedProduct: 'selectedProduct',
            titulosModalListResult: 'titulosModalListResult',
            titulosModalListRequestStatus: 'titulosModalListRequestStatus',
            exportTitulosFromOutgoFile: 'exportTitulosFromOutgoFile',
            exportTitulosFromOutgoFileRequestStatus: 'exportTitulosFromOutgoFileRequestStatus',
        }),
    },
};
</script>