export default [
    {
        text: 'Fornecedor',
        sortable: false,
        value: 'provider',
        align: 'left',
    },
    {
        text: 'Tipo',
        sortable: false,
        value: 'type',
        align: 'center',
        width: 176,
    },
    {
        text: 'Valor',
        sortable: false,
        value: 'nominalValue',
        align: 'center',
        width: 176,
    },
    {
        text: 'Vencimento',
        sortable: false,
        value: 'dueDate',
        align: 'center',
        width: 160,
    },
];
