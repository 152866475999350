<template>
    <v-main>
        <v-row justify="space-between">
            <v-col md="6" sm="12" cols="12" lg="6">
                <MainFilter @onInputChange="filterInputChanged" @onClick="toggleFilter" />
            </v-col>
            <v-col md="6" sm="12" cols="12" lg="6" align="right">
                <v-btn
                    @click="onClickNewOutgo"
                    depressed
                    class="white--text"
                    color="secondary"
                    x-large
                    title="Novo desembolso"
                    :disabled="!canRequest"
                >
                    <v-icon class="mr-2 v-icon-currency">mdi-currency-usd</v-icon>
                    Novo desembolso
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog content-class="modal-default modal-default-small" v-model="dialogOutgo">
            <header>
                Solicitação de desembolso
                <v-icon role="button" title="Fechar" @click="closeModalOutgo" color="altPrimary"
                    >mdi-close-thick</v-icon
                >
            </header>
            <v-main class="pt-12">
                <section>
                    <v-menu
                        v-model="menuOutgoDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                label="Escolha uma data"
                                prepend-icon="event"
                                readonly
                                :value="outgoDateDisplay"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            locale="pt-br"
                            v-model="outgoDate"
                            no-title
                            scrollable
                            :min="minDate"
                            @input="menuOutgoDate = false"
                        >
                        </v-date-picker>
                    </v-menu>

                    <footer align="right" class="mt-6">
                        <v-btn @click="resetModalOutgoDate" color="secondary" depressed
                            >Refazer</v-btn
                        >

                        <v-btn
                            class="ml-3"
                            :disabled="!outgoDate"
                            @click="confirmModalOutgoDate"
                            color="secondary"
                            depressed
                            >Confirmar</v-btn
                        >
                    </footer>
                </section>
            </v-main>
        </v-dialog>
        <v-row v-show="showFilters" class="mb-12">
            <Filters @onApply="searchListener" />
        </v-row>
        <div class="mt-6">
            <v-data-table
                :headers="headers"
                :items="titulosListResult.items"
                hide-default-footer
                show-select
                item-key="coverageId"
                id="table-gesta-titulos"
                class="elevation-0 pb-4 flat-container"
                v-model="selectedRows"
            >
                <template slot="no-data">
                    <div>Não há dados</div>
                </template>

                <template v-slot:[`item.dueDate`]="{ item }">
                    <span :class="'span-' + item.type.toLowerCase()">
                        {{ item ? formatDate(item.dueDate) : null }}
                    </span>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                    <span :class="'span-' + item.type.toLowerCase()">
                        <v-icon class="mr-1">mdi-file-document-outline</v-icon>
                        <v-chip class="status-chip" small>
                            {{ item.type }}
                        </v-chip>
                    </span>
                </template>

                <template v-slot:[`item.nominalValue`]="{ item }">
                    <span :class="'span-' + item.type.toLowerCase()">
                        {{ item ? formatarDinheiro(item.nominalValue) : null }}
                    </span>
                </template>

                <template v-slot:footer>
                    <v-pagination
                        color="secondary"
                        v-model="page"
                        class="mt-3"
                        :length="titulosListResult.totalPages"
                        :total-visible="7"
                    ></v-pagination>
                </template>
            </v-data-table>

            <Loader
                v-if="titulosListRequestStatus === RequestStatusEnum.START"
                class="text-center mt-3"
            />
        </div>
    </v-main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MainFilter from '@/components/base/mainfilter/MainFilter';
import Filters from '../components/_FiltersLista';

import headers from './_headers';
import { notification } from '@/helpers/notification';
import { formatDate } from '@/helpers/data';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';

export default {
    components: {
        MainFilter,
        Filters,
        Loader,
    },
    props: {
        outgoesFilterType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            showFilters: false,
            page: 1,
            headers,
            dialogOutgo: false,
            menuOutgoDate: null,
            outgoDate: null,
            lastSearchFilters: {},
            filters: {
                page: 0,
                limit: 10,
            },
            selectedRows: [],
            canRequest: false,
            RequestStatusEnum,
            allTitles: {},
        };
    },
    computed: {
        ...mapGetters('gestao', {
            resumeTitulosRequestStatus: 'resumeTitulosRequestStatus',
            resumeTitulos: 'resumeTitulos',
            titulosListResult: 'titulosListResult',
            titulosListRequestStatus: 'titulosListRequestStatus',
            askForNewOutgoRequestStatus: 'askForNewOutgoRequestStatus',
            selectedProduct: 'selectedProduct',
        }),

        outgoDateDisplay() {
            return formatDate(this.outgoDate);
        },
        minDate() {
            return new Date().toISOString();
        },
    },
    methods: {
        ...mapActions('gestao', {
            fetchTitulos: 'fetchTitulos',
            askForNewOutgo: 'askForNewOutgo',
            fetchOpenOutgoes: 'fetchOpenOutgoes',
        }),
        filterInputChanged(value) {
            this.filters.nomeOuDoc = value;
            if (value.length >= 3 || value === '') {
                this.doSearch();
            }
        },
        toggleFilter() {
            this.showFilters = !this.showFilters;
        },
        searchListener(data) {
            const nomeOuDoc = this.filters.nomeOuDoc || '';
            this.filters = { ...data, page: 0, limit: 10 };
            this.filters.nomeOuDoc = nomeOuDoc;
            this.doSearch();
        },
        onClickNewOutgo() {
            this.dialogOutgo = true;
        },
        closeModalOutgo() {
            this.resetModalOutgoDate();
            this.dialogOutgo = false;
        },
        resetModalOutgoDate() {
            this.outgoDate = null;
        },
        confirmModalOutgoDate() {
            const ids = this.selectedRows.map(item => item.coverageId);
            this.askForNewOutgo({
                idProduct: this.selectedProduct.id,
                date: this.outgoDate,
                ids,
            });
            this.dialogOutgo = false;
        },
        doSearch() {
            if (!this.filters.nomeOuDoc) {
                delete this.filters.nomeOuDoc;
            }
            this.lastSearchFilters = { ...this.filters };
            this.fetchTitulos({
                idProduct: this.selectedProduct.id,
                status: 1,
                filters: { ...this.filters },
            });
        },
        formatarDinheiro,
        formatDate,
    },
    mounted() {
        this.doSearch();
    },
    watch: {
        page() {
            this.filters.page = this.page - 1;
            this.doSearch();
        },
        selectedRows(data) {
            this.canRequest = Object.keys(data).length > 0;
        },
        titulosListResult(result) {
            result.items.forEach(item => (this.allTitles[item.idLastro] = item));
        },
        askForNewOutgoRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.START) {
                notification(this.$dialog.notify.warning, 'Enviado pedido de novo dembolso');
                return RequestStatusEnum.START;
            }
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(
                    this.$dialog.notify.success,
                    'Pedido de novo dembolso solicitado com sucesso'
                );
                this.selectedRows = [];
                this.fetchOpenOutgoes({
                    idProduct: this.selectedProduct.id,
                    status: '1,2',
                    filters: { tipo: this.outgoesFilterType },
                });
                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Ocrreu um erro ao solicitar novo desembolso' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.v-icon-currency {
    border: 2px solid white;
    border-radius: 50%;
}
@import './_tableTitulos.scss';
</style>
<style lang="scss">
@import '@/assets/scss/Sticky-table';
@include stickytable('#table-gesta-titulos', 2);
</style>