<template>
    <v-container>
        <v-row v-if="resumeTitulosRequestStatus === RequestStatusEnum.SUCCESS">
            <v-col lg="12" xl="12" cols="12" class="mt-0 pb-0 pt-0">
                <div id="wrapper">
                    <div id="chart-area">
                        <apexchart
                            height="272px"
                            type="donut"
                            :series="series"
                            :options="chartOptions"
                        ></apexchart>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row
            align="center"
            justify="center"
            v-if="resumeTitulosRequestStatus === RequestStatusEnum.START"
        >
            <v-progress-circular
                :size="70"
                :width="6"
                color="secondary"
                indeterminate
            ></v-progress-circular>
        </v-row>
        <v-row v-if="resumeTitulosRequestStatus.type === RequestStatusEnum.ERROR">
            <v-alert
                align="center"
                justify="center"
                dense
                outlined
                type="error"
                class="mt-6 ml-3 mr-3"
            >
                Ocorreu um erro ao requisitar dados para o gráfico.
            </v-alert>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import chartOptions from './_chartOptions';
import { RequestStatusEnum } from '@/helpers/consts';
export default {
    data() {
        return {
            chartOptions,
            series: [],
            RequestStatusEnum,
        };
    },
    computed: {
        ...mapGetters('gestao', {
            resumeTitulosRequestStatus: 'resumeTitulosRequestStatus',
            resumeTitulos: 'resumeTitulos',
            selectedProduct: 'selectedProduct',
        }),
    },
    mounted() {
        this.fetchResume({ idProduct: this.selectedProduct.id });
    },
    methods: {
        ...mapActions('gestao', {
            fetchResume: 'fetchResume',
        }),
    },
    watch: {
        resumeTitulos(newValue) {
            this.series = ['contaAPagar', 'emEmissao', 'emAssinatura', 'desembolsado'].map(
                key => newValue[key].qtdNotas
            );
        },
    },
};
</script>