var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12","lg":"6"}},[_c('MainFilter',{on:{"onInputChange":_vm.filterInputChanged,"onClick":_vm.toggleFilter}})],1),_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12","lg":"6","align":"right"}},[_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"secondary","x-large":"","title":"Novo desembolso","disabled":!_vm.canRequest},on:{"click":_vm.onClickNewOutgo}},[_c('v-icon',{staticClass:"mr-2 v-icon-currency"},[_vm._v("mdi-currency-usd")]),_vm._v(" Novo desembolso ")],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"modal-default modal-default-small"},model:{value:(_vm.dialogOutgo),callback:function ($$v) {_vm.dialogOutgo=$$v},expression:"dialogOutgo"}},[_c('header',[_vm._v(" Solicitação de desembolso "),_c('v-icon',{attrs:{"role":"button","title":"Fechar","color":"altPrimary"},on:{"click":_vm.closeModalOutgo}},[_vm._v("mdi-close-thick")])],1),_c('v-main',{staticClass:"pt-12"},[_c('section',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Escolha uma data","prepend-icon":"event","readonly":"","value":_vm.outgoDateDisplay}},on))]}}]),model:{value:(_vm.menuOutgoDate),callback:function ($$v) {_vm.menuOutgoDate=$$v},expression:"menuOutgoDate"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","no-title":"","scrollable":"","min":_vm.minDate},on:{"input":function($event){_vm.menuOutgoDate = false}},model:{value:(_vm.outgoDate),callback:function ($$v) {_vm.outgoDate=$$v},expression:"outgoDate"}})],1),_c('footer',{staticClass:"mt-6",attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"secondary","depressed":""},on:{"click":_vm.resetModalOutgoDate}},[_vm._v("Refazer")]),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":!_vm.outgoDate,"color":"secondary","depressed":""},on:{"click":_vm.confirmModalOutgoDate}},[_vm._v("Confirmar")])],1)],1)])],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],staticClass:"mb-12"},[_c('Filters',{on:{"onApply":_vm.searchListener}})],1),_c('div',{staticClass:"mt-6"},[_c('v-data-table',{staticClass:"elevation-0 pb-4 flat-container",attrs:{"headers":_vm.headers,"items":_vm.titulosListResult.items,"hide-default-footer":"","show-select":"","item-key":"coverageId","id":"table-gesta-titulos"},scopedSlots:_vm._u([{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'span-' + item.type.toLowerCase()},[_vm._v(" "+_vm._s(item ? _vm.formatDate(item.dueDate) : null)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'span-' + item.type.toLowerCase()},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-file-document-outline")]),_c('v-chip',{staticClass:"status-chip",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.type)+" ")])],1)]}},{key:"item.nominalValue",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'span-' + item.type.toLowerCase()},[_vm._v(" "+_vm._s(item ? _vm.formatarDinheiro(item.nominalValue) : null)+" ")])]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"secondary","length":_vm.titulosListResult.totalPages,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Não há dados")])])],2),(_vm.titulosListRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }