<template>
    <v-main>
        <section id="tables-container" class="mt-6">
            <div :class="`${NFsModalListResult.items.length > 0 ? 'table-hide' : ''}`">
                <v-col sm="12" class="mb-6 mt-3 pa-0" align="right">
                    <ExportButton
                        @onClick="
                            type === 'eligibles'
                                ? onExportEligiblesClients()
                                : onExportIneligiblesClients()
                        "
                        :status="exportFromFactoryRequestStatus"
                    />
                </v-col>
                <v-data-table
                    :headers="headers"
                    :items="clientsModalListResult.items"
                    hide-default-footer
                    :class="`elevation-1 pb-4`"
                    item-key="id"
                    :show-select="canSelect"
                    v-model="selectedRowsClients"
                    @item-selected="itemSelected"
                    @click:row="rowClick"
                    ref="tableClients"
                >
                    <template slot="no-data">
                        <div>Não há dados</div>
                    </template>

                    <template v-slot:[`item.name`]="{ item }">
                        <span class="span-pointer">{{ item ? item.name : null }}</span>
                    </template>

                    <template v-slot:[`item.date`]="{ item }">
                        {{ item ? formatDate(item.date) : null }}
                    </template>

                    <template v-slot:[`item.value`]="{ item }">
                        {{ item ? formatarDinheiro(item.value) : null }}
                    </template>

                    <template v-slot:footer>
                        <v-pagination
                            color="secondary"
                            v-model="page"
                            class="mt-3"
                            :length="clientsModalListResult.totalPages"
                            :total-visible="7"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </div>

            <div>
                <v-row class="mb-6 mt-3 ml-0 mr-0">
                    <v-col sm="12" md="6" align="left">
                        <v-btn color="secondary" title="Voltar" small outlined @click="dismissNFs">
                            <v-icon small> mdi-chevron-left </v-icon>
                            Voltar
                        </v-btn>
                    </v-col>
                    <v-col sm="12" md="6" class="pa-0" align="right">
                        <ExportButton
                            v-if="type === 'eligibles'"
                            @onClick="onExportEligiblesClientNFs"
                            :status="exportFromFactoryRequestStatus"
                        />
                        <ExportButton
                            v-if="type === 'ineligibles'"
                            @onClick="onExportIneligiblesClienttNFs"
                            :status="exportFromFactoryRequestStatus"
                        />
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="modalRemessasNFsHeaders"
                    :items="NFsModalListResult.items"
                    hide-default-footer
                    class="elevation-1 pb-4 mt-6"
                    item-key="id"
                    :show-select="canSelect"
                    v-model="selectedRowsNFs"
                    @item-selected="itemSelectedNF"
                >
                    <template v-slot:[`item.issuedAt`]="{ item }">
                        {{ item ? formatDate(item.issuedAt) : null }}
                    </template>

                    <template v-slot:[`item.dueDate`]="{ item }">
                        {{ item ? formatDate(item.dueDate) : null }}
                    </template>

                    <template v-slot:[`item.value`]="{ item }">
                        {{ item ? formatarDinheiro(item.value) : null }}
                    </template>
                    <template v-slot:footer>
                        <v-pagination
                            color="secondary"
                            v-model="page"
                            class="mt-3"
                            :length="NFsModalListResult.totalPages"
                            :total-visible="7"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </div>
        </section>

        <Loader
            v-if="
                clientsModalListRequestStatus === RequestStatusEnum.START ||
                NFsModalListRequestStatus === RequestStatusEnum.START
            "
            class="text-center mt-3"
        />
    </v-main>
</template>
<style scoped lang="scss">
@import './_ModalRemessas.scss';
</style>
<script>
import { mapActions, mapGetters } from 'vuex';
import headers from './_modalRemessasClientesHeaders';
import modalRemessasNFsHeaders from './_ModalRemessasNFsHeaders';
import { formatDate } from '@/helpers/data';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';
import Loader from '@/components/base/Loader';
import { RequestStatusEnum } from '@/helpers/consts';
import ExportButton from '@/components/base/ExportButton';
export default {
    components: {
        Loader,
        ExportButton,
    },
    props: {
        canSelect: {
            type: Boolean,
            default: false,
        },
        id: {
            type: Number,
        },
        type: {
            type: String,
        },
    },
    data() {
        return {
            headers,
            modalRemessasNFsHeaders,
            totalPages: null,
            page: 1,
            selectedRowsClients: [],
            removedItemsClients: [],
            selectedRowsNFs: [],
            removedItemsNFs: [],
            RequestStatusEnum,
            pageNF: 1,
            currentClientId: null,
        };
    },

    methods: {
        ...mapActions('gestao', {
            fetchClientsModal: 'fetchClientsModal',
            fetchNFsFromClientModal: 'fetchNFsFromClientModal',
            dismissNFsModalList: 'dismissNFsModalList',
            exportClientsFromOutgo: 'exportClientsFromOutgo',
            exportClientNFsFromOutgo: 'exportClientNFsFromOutgo',
        }),
        load() {
            this.resetData();
            this.fetchClientsModal({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                type: this.type,
                page: this.page - 1,
            });
        },
        checkAll(status) {
            if (status) {
                this.selectedRowsClients = this.clientsModalListResult.items.filter(item => {
                    return this.removedItemsClients.indexOf(item.id) < 0;
                });
            }
        },
        checkAllNFs(status) {
            if (status) {
                this.selectedRowsNFs = this.NFsModalListResult.items.filter(item => {
                    return this.removedItemsNFs.indexOf(item.id) < 0;
                });
            }
        },
        itemSelected(newValue) {
            if (newValue.value === false) {
                this.removedItemsClients.push(newValue.item.id);
            } else {
                this.removedItemsClients = this.removedItemsClients.filter(
                    value => value !== newValue.item.id
                );
            }
            this.$emit('onItemRemove', this.removedItemsClients);
        },
        itemSelectedNF(newValue) {
            if (newValue.value === false) {
                this.removedItemsNFs.push(newValue.item.id);
            } else {
                this.removedItemsNFs = this.removedItemsNFs.filter(
                    value => value !== newValue.item.id
                );
            }
              this.$emit('onItemRemoveNF', this.removedItemsNFs);
        },
        formatDate,
        formatarDinheiro,
        rowClick(item) {
            this.currentClientId = item.id;
            this.fetchNFsFromClientModal({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                clientId: item.id,
                type: this.type,
                page: this.pageNF - 1,
            });
        },
        dismissNFs() {
            this.dismissNFsModalList();
        },
        onExportEligiblesClients() {
            this.exportClientsFromOutgo({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                type: 'eligibles',
            });
        },
        onExportIneligiblesClients() {
            this.exportClientsFromOutgo({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                type: 'ineligibles',
            });
        },
        onExportEligiblesClientNFs() {
            this.exportClientNFsFromOutgo({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                clientId: this.currentClientId,
                type: 'eligibles',
            });
        },
        onExportIneligiblesClientNFs() {
            this.exportClientNFsFromOutgo({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                clientId: this.currentClientId,
                type: 'ineligibles',
            });
        },
        resetData() {
            this.dismissNFsModalList();
            this.totalPages = null;
            this.page = 1;
            this.selectedRowsClients = [];
            this.removedItemsClients = [];
            this.selectedRowsNFs = [];
            this.removedItemsNFs = [];
            this.pageNF = 1;
            this.currentClientId = null;
        }
    },
    watch: {
        page() {
            this.fetchClientsModal({
                idProduct: this.selectedProduct.id,
                outgoId: this.id,
                type: this.type,
                page: this.page - 1,
            });
        },
        clientsModalListResult() {
            this.checkAll(this.canSelect);
        },
        NFsModalListResult() {
            this.checkAllNFs(this.canSelect);
        },
    },
    computed: {
        ...mapGetters('gestao', {
            selectedProduct: 'selectedProduct',
            clientsModalListResult: 'clientsModalListResult',
            NFsModalListResult: 'NFsModalListResult',
            NFsModalListRequestStatus: 'NFsModalListRequestStatus',
            clientsModalListRequestStatus: 'clientsModalListRequestStatus',
            exportFromFactoryRequestStatus: 'exportFromFactoryRequestStatus',
        }),
    },
};
</script>