<template>
    <v-btn
        @click="onClick"
        depressed
        color="secondary"
        large
        outlined
        :disabled="status === RequestStatusEnum.START"
        title="Exportar"
    >
        Exportar
        <Loader size="small" class="ml-5" v-if="status === RequestStatusEnum.START" />
        <v-icon class="ml-5" v-if="status !== RequestStatusEnum.START">mdi-download</v-icon>
    </v-btn>
</template>
<script>
import Loader from './Loader';
import { RequestStatusEnum } from '@/helpers/consts';
export default {
    components: {
        Loader,
    },
    props: {
        status: {
            default: RequestStatusEnum.IDLE,
        },
    },
    data: () => ({
        RequestStatusEnum,
    }),
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
};
</script>