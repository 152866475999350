export const formatDataLabelShowValue = function(_, item) {
    return item.w.globals.series[item.seriesIndex];
};

export const legendFormatter = function(seriesName, opts) {
    return [
        '&nbsp;&nbsp;' +
            seriesName +
            '&nbsp;&nbsp;<span class="apexcharts-legend-text-count">' +
            opts.w.globals.series[opts.seriesIndex] +
            '</span>',
    ];
};

export default {
    chart: {
        type: 'donut',
    },
    labels: ['Contas a Pagar', 'Em emissão', 'Em assinatura', 'Desembolso'],
    legend: {
        position: 'bottom',
        fontSize: '16px',
        fontFamily: 'Roboto',
        itemMargin: {
            horizontal: 10,
        },
        formatter: legendFormatter,
    },
    colors: ['#2C5994', '#308CCC', '#00E396', '#0C7A69'],
    dataLabels: {
        enabled: true,
        formatter: formatDataLabelShowValue,
    },
};
