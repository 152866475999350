<template>
    <section id="openoutgoes-list-container" :class="showAllOpenOutgoes ? 'show' : 'hide'">
        <div class="mb-9" v-for="outgo in sortedList" v-bind:key="outgo.id">
            <OutgoDetail
                :outgoData="outgo"
                :routeType="routeType"
                @onSendOutgo="onSendOutgo"
                @onCancelOutgo="onCancelOutgo"
                @onConfirmEligibles="onConfirmEligibles"
            />
        </div>
        <v-row class="d-flex justify-end" v-if="openOutgoesList.length > 1">
            <v-btn
                depressed
                outlined
                color="secondary"
                @click="showAllOpenOutgoes = !showAllOpenOutgoes"
            >
                <span v-if="showAllOpenOutgoes">Esconder</span>
                <span v-else>Exibir mais</span>
            </v-btn>
        </v-row>
    </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OutgoDetail from './_OutgoDetail';
export default {
    components: {
        OutgoDetail,
    },
    props: {
        routeType: {
            type: String,
            default: 'origination',
        },
    },
    data() {
        return {
            showAllOpenOutgoes: false,
        };
    },
    computed: {
        ...mapGetters('gestao', {
            openOutgoesList: 'openOutgoesList',
            selectedProduct: 'selectedProduct',
            openOutgoesListRequestStatus: 'openOutgoesListRequestStatus',
        }),
        sortedList() {
            return [...this.openOutgoesList].sort(o => (o.status === 1 ? -1 : 1));
        },
    },
    methods: {
        ...mapActions('gestao', {
            sendOutgo: 'sendOutgo',
            cancelOutgo: 'cancelOutgo',
        }),
        onSendOutgo(outgoId) {
            this.sendOutgo({ idProduct: this.selectedProduct.id, outgoId });
        },
        onCancelOutgo(outgoId) {
            this.cancelOutgo({ idProduct: this.selectedProduct.id, outgoId });
        },
        onConfirmEligibles(outgoId, meta) {
            this.$emit('onConfirmEligibles', outgoId, meta);
        },
    },
};
</script>
<style scoped lang="scss">
#openoutgoes-list-container.hide > div:not(.row) {
    display: none;
    &:first-child {
        display: block;
    }
}
</style>