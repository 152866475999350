var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('section',{staticClass:"mt-6",attrs:{"id":"tables-container"}},[_c('div',{class:("" + (_vm.NFsModalListResult.items.length > 0 ? 'table-hide' : ''))},[_c('v-col',{staticClass:"mb-6 mt-3 pa-0",attrs:{"sm":"12","align":"right"}},[_c('ExportButton',{attrs:{"status":_vm.exportFromFactoryRequestStatus},on:{"onClick":function($event){_vm.type === 'eligibles'
                            ? _vm.onExportEligiblesClients()
                            : _vm.onExportIneligiblesClients()}}})],1),_c('v-data-table',{ref:"tableClients",class:"elevation-1 pb-4",attrs:{"headers":_vm.headers,"items":_vm.clientsModalListResult.items,"hide-default-footer":"","item-key":"id","show-select":_vm.canSelect},on:{"item-selected":_vm.itemSelected,"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                            var item = ref.item;
return [_c('span',{staticClass:"span-pointer"},[_vm._v(_vm._s(item ? item.name : null))])]}},{key:"item.date",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item ? _vm.formatDate(item.date) : null)+" ")]}},{key:"item.value",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item ? _vm.formatarDinheiro(item.value) : null)+" ")]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"secondary","length":_vm.clientsModalListResult.totalPages,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,true),model:{value:(_vm.selectedRowsClients),callback:function ($$v) {_vm.selectedRowsClients=$$v},expression:"selectedRowsClients"}},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Não há dados")])])],2)],1),_c('div',[_c('v-row',{staticClass:"mb-6 mt-3 ml-0 mr-0"},[_c('v-col',{attrs:{"sm":"12","md":"6","align":"left"}},[_c('v-btn',{attrs:{"color":"secondary","title":"Voltar","small":"","outlined":""},on:{"click":_vm.dismissNFs}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" Voltar ")],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"sm":"12","md":"6","align":"right"}},[(_vm.type === 'eligibles')?_c('ExportButton',{attrs:{"status":_vm.exportFromFactoryRequestStatus},on:{"onClick":_vm.onExportEligiblesClientNFs}}):_vm._e(),(_vm.type === 'ineligibles')?_c('ExportButton',{attrs:{"status":_vm.exportFromFactoryRequestStatus},on:{"onClick":_vm.onExportIneligiblesClienttNFs}}):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1 pb-4 mt-6",attrs:{"headers":_vm.modalRemessasNFsHeaders,"items":_vm.NFsModalListResult.items,"hide-default-footer":"","item-key":"id","show-select":_vm.canSelect},on:{"item-selected":_vm.itemSelectedNF},scopedSlots:_vm._u([{key:"item.issuedAt",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item ? _vm.formatDate(item.issuedAt) : null)+" ")]}},{key:"item.dueDate",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item ? _vm.formatDate(item.dueDate) : null)+" ")]}},{key:"item.value",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item ? _vm.formatarDinheiro(item.value) : null)+" ")]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"secondary","length":_vm.NFsModalListResult.totalPages,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,true),model:{value:(_vm.selectedRowsNFs),callback:function ($$v) {_vm.selectedRowsNFs=$$v},expression:"selectedRowsNFs"}})],1)]),(
            _vm.clientsModalListRequestStatus === _vm.RequestStatusEnum.START ||
            _vm.NFsModalListRequestStatus === _vm.RequestStatusEnum.START
        )?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }